import React from 'react';
// import { useStaticQuery, graphql } from "gatsby"

// import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"

import FlipCardGallery from '../components/flip-card-gallery'

// const useStyles = makeStyles(theme => ({
//     root: {
//       flexGrow: 1,
//     },
//     paper: {
//       padding: theme.spacing(2),
//       textAlign: 'center',
//       color: theme.palette.text.secondary,
//     },
//     productionsWrapper: {
//       paddingBottom: "1em",
//       maxWidth: "85rem",
//       margin: "20px auto",
//       display: "flex",
//       flexDirection: "column"
//     },
//     showsWrapper: {
//       paddingBottom: "1em",
//       maxWidth: "60rem",
//       margin: "20px auto",
//       display: "flex",
//       flexDirection: "column"
//     },
//     button: {
//       margin: theme.spacing(1),
//     },
//     dropTop: {
//         paddingBottom: "100%",
//         borderRadius: "50%"
//       },
//     jumbo: {
//     height: "100vh"
//     },
//   }));

export default function CenteredGrid() {
    // const classes = useStyles();

  //   const data = useStaticQuery(graphql`
  //   query {
  //     frankenstein: file(relativePath: { eq: "0Edited6.jpg" }) {
  //       childImageSharp {
  //         fluid(quality: 100) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     },
  //     church: file(relativePath: { eq: "church-sanctuarywithcandles.jpg" }) {
  //       childImageSharp {
  //         fluid(quality: 100) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     },
  //     frankenSquare: file(relativePath: { eq: "frankenSquare.jpg" }) {
  //       childImageSharp {
  //         fluid(quality: 100) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     },
  //     squareShelley: file(relativePath: { eq: "squareShelley.jpg" }) {
  //       childImageSharp {
  //         fluid(quality: 100) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     },
  //   }
  // `)
  
    return (
    <Layout>
    <SEO title="Frankenstein's Funeral" description="Frankenstein's Funeral: An Immersive Halloween Experience at St. John's Lutheran Church in Atlanta, GA" />
      
    <div class="jumbotron-wrapper-home">
      <div class="jumbo-inner-wrapper">
        <div className="jumbo-title-wrapper">
            <h1 class="jumbo-title-home">
                Frankenstein's Funeral
            </h1>
            <h5 class="subtitle-wine">An Immersive Halloween Experience</h5>
            {/* <br></br>
            <h5 class="subtitle-wine">St. John's Lutheran Church, Atlanta</h5>
            <div class="wine-buttons">
                <a title="Get Tickets" href="https://www.eventbrite.com/o/found-stages-18287682913" class="button button--bigger blackButton--border">
                  Get Tickets
                </a>
                <a title="Become a Supporter" href="https://secure.givelively.org/donate/found-stages-inc/matching-challenge-for-frankenstein-s-funeral" class="button button--bigger blackButton--border">
                    Become a Supporter
                </a>
          </div> */}
        </div>
      </div>
    </div>
        {/* <BackgroundImage 
                    Tag="section"
                    className={classes.jumbo}
                    fluid={data.frankenstein.childImageSharp.fluid}
                    backgroundColor={`#040e18`}
                    >
        <div class="jumbo-inner-wrapper-frankenstein">
            <div>
              <h1 class="jumbo-title-wine">
                  Frankenstein's Funeral
              </h1>
              <h5 class="subtitle-wine">At St. John's Lutheran Church</h5>
              <div class="wine-buttons">
                  <a title="Get Tickets" href="https://www.eventbrite.com/o/found-stages-18287682913" class="button button--bigger blackButton--border">
                      Get Tickets
                      </a>
                  <a title="Become a Supporter" href="https://secure.givelively.org/donate/found-stages-inc/frankenstein-s-funeral" class="button button--bigger blackButton--border">
                      Become a Supporter
                  </a>
            </div>
            </div>
        </div>
        </BackgroundImage> */}

      {/* <Grid container spacing={3} className={classes.productionsWrapper}> */}
        <div>
            <div class="frankenstein-content-block">
              <h3 class="frankenstein-description">
              Frankenstein’s Funeral takes audiences on a physical journey, guided by Mary Shelley, through her novel and the Gothic campus of St. John’s Lutheran Church at Halloween.              </h3>
            </div>  
          </div>

        <div class="show-cards-wrapper">
          <div class="wrapper">
              <div class="top card-7">
              {/* <BackgroundImage 
                    Tag="div"
                    className={classes.dropTop}
                    fluid={data.church.childImageSharp.fluid}
                    backgroundColor={`#040e18`}
                    >
                </BackgroundImage> */}
                </div>
                  <div class="bottom">
                      <h4 class="card-title centered-text">Explore</h4>
                      <p class="production-card__text">
                      Step into a world of suspense and wonder as you journey with the characters throughout multiple rooms on the Gothic, hallowed grounds of St. John's Lutheran Church.
                      </p>
                      {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span>
                      <p class="production-card__locations">GLASGOW</p> */}
                      
                </div>
            </div>
            <div class="wrapper">
                <div class="top card-8">
                {/* <BackgroundImage 
                    Tag="div"
                    className={classes.dropTop}
                    fluid={data.frankenSquare.childImageSharp.fluid}
                    backgroundColor={`#040e18`}
                    >
                </BackgroundImage> */}
                    </div>
                    <div class="bottom">
                        <h4 class="card-title centered-text">Activate</h4>
                        <p class="production-card__text">
                        Experience this 200-year-old tale with all your senses as you engage in the story through collective actions that lead Victor Frankenstein and his monstrous creation on paths of destruction and woe.
                        </p>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span>
                        <p class="production-card__locations">GLASGOW</p> */}
                       
                </div>
            </div>
            <div class="wrapper">
                <div class="top card-9">
                {/* <BackgroundImage 
                    Tag="div"
                    className={classes.dropTop}
                    fluid={data.squareShelley.childImageSharp.fluid}
                    backgroundColor={`#040e18`}
                    >
                </BackgroundImage> */}
                    </div>
                    <div class="bottom">
                        <h4 class="card-title centered-text">Engage</h4>
                        <p class="production-card__text">
                        Share in this one-of-a-kind adventure as you follow the author Mary Shelley into the haunted recesses of her timeless imagination.
                        </p>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span>
                        <p class="production-card__locations">GLASGOW</p> */}
                     
                </div>
            </div>
        </div>
        <div class="lesspadding-title-wrapper">
              <div class="productions-title">
                  Relive This One of a Kind Experience
              </div>
          </div>
            <div class="podplay-row">
                <iframe class="i-frame" title="The Making of Frankenstein's Ball" src="https://www.youtube.com/embed/EMqoYkUc28w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                {/* <iframe class="i-frame" title="Behind the Music of Frankenstein's Ball" src="https://www.youtube.com/embed/5O070TSEuik" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>
        <FlipCardGallery /> 
      {/* </Grid> */}
    </Layout>
    )
}
