import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "#000",
    border: '2px solid #ffd700',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%',
    [theme.breakpoints.down('md')]: {
        width: '75%',
        padding: 10
      },
    [theme.breakpoints.down('sm')]: {
    width: '94%',
    },
  },
  button: {
      zIndex: 10,
      marginBottom: 0,
      color: '#cacaca',
      textAlign: 'center',
      cursor: 'pointer'
  },
  para: {
    color: "#fff",
    marginBottom: 0,
    fontSize: 15,
    lineHeight: 1.25,
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        },
  }
}));

export default function CrewCard({ name, role, cardNumber, bio}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="crew-card-wrapper">
        <div className={`crew-card crew-${cardNumber}`}>
        <div className="crew-card-overlay">
            </div>
            <span className="crew-caption">
                <h2>{name}</h2>
                <h3>{role}</h3>
                { 
                    bio ? (<h5 onClick={handleOpen} className={classes.button}>
                            Read bio
                            </h5>) :
                            ( null )
                }
            </span>
        </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div id="transition-modal-description" className={classes.para}>{bio}</div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
