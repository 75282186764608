import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import CrewCard from './crewCard'

import { FaAngleDown } from 'react-icons/fa';
import '../styles/flip-card-gallery.css'

// image imports
import stryant from'../images/frankensteins-funeral-sponsor-logos/stryant.png'
import vibram from'../images/frankensteins-funeral-sponsor-logos/vibram.png'
import avGlobal from '../images/frankensteins-funeral-sponsor-logos/av-global.png'
import gaslight from '../images/frankensteins-funeral-sponsor-logos/gaslight.png'
import jordan from '../images/frankensteins-funeral-sponsor-logos/jordan.png'
import odditees from '../images/frankensteins-funeral-sponsor-logos/odditees.png'
import casey from '../images/frankensteins-funeral-sponsor-logos/casey-gardner-photography.png'
import dap from '../images/frankensteins-funeral-sponsor-logos/dap.png'



// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//     backgroundColor: theme.palette.background.paper,
//     overflowY: 'none'
//   },
//   gridList: {
//     width: 500,
//     height: 450,
//     overflowY: 'none'
//   },
//   override: {
//     overflowY: 'none'
//   }
  
// }));

export default function ImageGridList() {

  return (

    <div className="container">

	<h1 className="heading">Meet the Cast of Frankenstein's Funeral</h1>

	<div className="gallery">

        <div className="gallery-row">
            <div className="flip-gallery-item" id="jennifer-headshot">
                <span className="flip-caption">
                    <h2>Jennifer Schottstaedt</h2>
                    <div className="bio-bar">
                        <h3>Mary Shelley</h3>
                            <div className="bio-arrow-wrapper">
                                <h5>Read bio</h5>
                                <FaAngleDown className="bio-arrow" />
                            </div>
                        </div>
                <p className="desc">
                Jennifer Schottstaedt (Costume Designer) most recently designed the costumes for Théâtre du Rêve's productions of Il était une fois/Once Upon A Time (for which she was nominated for a Suzi Bass Award for Outstanding Costume Design) and Le Petit Prince, and has worked in the costume shops of the Santa Fe Opera (Stitcher), the Chautauqua Opera (Draper) and the Texas Shakespeare Festival (First Hand).<br></br><br></br> 
                She has previously been seen onstage at Synchronicity Theatre (Sense and Sensibility), Actor's Express (Les Liaisons Dangereuses), Théâtre du Rêve (Il était une fois/Once Upon A Time; Jane, The Fox and Me; Le Petit Prince), Georgia Shakespeare (Hamlet) and Pinch 'n' Ouch (Glengarry Glen Ross).<br></br><br></br>
                She received her BFA in Theatre with an emphasis in Costume Design & Construction from the University of Evansville and her MFA in Acting from the University of Georgia.  
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken3} alt="people holding umbrellas on a busy street at night lit by street lights and illuminated signs in Tokyo, Japan"/> */}
            </div>
            <div className="flip-gallery-item" id="jake-headshot">
                <span className="flip-caption">
                    <h2>Jake Krakovsky</h2>
                    <div className="bio-bar">
                        <h3>Victor Frankenstein</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Jake Krakovsky is an actor, puppeteer, writer, teaching artist, and lifelong ATLien. He has performed with many local companies including The Alliance Theatre, The Center for Puppetry Arts, Theater Emory, and others. <br></br><br></br>
                Jake teaches workshops and coaches performers, from grade school to university to professional companies, in puppetry, clowning, and other physical theater skills and traditions. <br></br><br></br>
                In 2017, Jake was awarded a residency by the Collaborative Arts Lab in Arezzo, Italy to study and train in ensemble devising. <br></br><br></br>
                In 2018, Jake worked as a full-time puppeteer on “Moon and Me,” a BBC preschool tv show from the creator of Teletubbies. <br></br><br></br>
                Jake is the recipient of a 2019/2019 Reiser Atlanta Artists Lab fellowship.            
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken1} alt="Victor Frankenstein"/> */}
            </div>

            <div className="flip-gallery-item" id="joseph-headshot">
                <span className="flip-caption">
                    <h2>Joseph Pendergrast</h2>
                    <div className="bio-bar">
                        <h3>The Monster</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Joseph is tickled pink to be making their Found Stages debut.<br></br><br></br> 
                Atlanta credits include Head Over Heels at Actor's express, Oliver!, and Barnum! at the Atlanta Lyric, Newsies!, Mamma Mia!, Hunchback of Notre Dame, In the Heights, and Mary Poppins at the Aurora as co productions with The atlanta Lyric, and Theatrical Outfit, Midsummer Night's Dream with Theatre Argo, Ever After and Alice Between at the Alliance Theatre.<br></br><br></br> 
                Jo owns a BA in theatre and performance studies from Kennesaw State University, and is being hired back to Co-choreograph their production of Bring it On this fall.<br></br><br></br> 
                Thanks to Mom and Dad for their comprehensive support of everything worthwhile in my life. The good in me, I got from you.<br></br><br></br> 
                Instagram: <a href="http://www.instagram.com/thependergram" target="_blank" rel="noopener noreferrer"><strong className="white-link">@thePendergram</strong></a>           
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken2} alt="Mary Shelley"/> */}
            </div>
        </div>
        <div className="gallery-row">
            <div className="flip-gallery-item" id="michelle-headshot">
                <span className="flip-caption">
                    <h2>Michelle Pokopac</h2>
                    <div className="bio-bar">    
                        <h3>Elizabeth Lavenza</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Michelle Pokopac is an Atlanta-based actor in both theatre and film. She is an Honors graduate from Columbus State University with a BFA in Theatre Performance and has also trained in Florence, Italy and Oxford, England.<br></br><br></br> 
                Michelle is Co-Founder/Producer of East by Southeast which supports and develops local Asian artists in Atlanta.<br></br><br></br> 
                Recent theatre credits include: The Wolves (Horizon Theatre), Little Raindrop Songs, Alice Between, Slur, Play the Play with Cat the Cat (Alliance Theatre), Sense and Sensibility (Synchronicity Theatre), James and the Giant Peach, On the Verge (Georgia Ensemble), Who's Afraid of Virginia Woolf, Water by the Spoonful (Pinch 'N' Ouch), The Joy Luck Club (Found Stages), A Thousand Cranes, Señora Tortuga (Lexington Children's Theatre).<br></br><br></br> 
                You can also catch Michelle on Season 3 of STAR on FOX.<br></br><br></br> 
                Instagram: <a href="http://www.instagram.com/pokopac" target="_blank" rel="noopener noreferrer"><strong className="white-link">@pokopac</strong></a> <br></br>
                <a href="http://www.instagram.com/east_x_southeast" target="_blank"><strong className="white-link">@east_x_southeast</strong></a> 
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken4} alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"/> */}
            </div>
            <div className="flip-gallery-item" id="erika-headshot">
                <span className="flip-caption">
                    <h2>Erika Miranda</h2>
                    <div className="bio-bar">    
                        <h3>Justine Moritz</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Erika Miranda (Justine) is very excited and grateful to be playing with Found Stages for the first time this October.<br></br><br></br> 
                She received her BFA in Acting at The Theater School at DePaul University and a Certificate in Classical Acting from the London Academy of Music & Dramatic Arts. Upon graduating she performed with some of Chicago's favorite storefronts, but a role in Gallows: Act II (Lionsgate Films) brought her to LA where she also worked with the Echo Theater on the world premiere of Bekah Brunstetter's The Cake.<br></br><br></br> 
                Recent ATL credits include: The Wolves (Horizon Theater) and Slaying Holofernes (Essential Theater). Catch her as Lydia in The Wickhams (Theatrical Outfit) this winter!<br></br><br></br> 
                As always a big thank you to her family and friends for the unwavering support! 
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken6} alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp"/> */}
            </div>
            <div className="flip-gallery-item" id="dane-headshot">
                <span className="flip-caption">
                    <h2>Dane LeAnna</h2>
                    <div className="bio-bar">    
                        <h3>The Bride</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Raised in Eugene, Oregon, Dane LeAnna trained with the Eugene Ballet Academy and Eugene Ballet Company, performing works such as, Romeo & Juliet, Cinderella, The Sleeping Beauty, Peter Pan and The Nutcracker.<br></br><br></br>

                Dane received a full merit scholarship to attend the University of North Carolina School of the Arts to continue her dance training. There, she performed works by former Deans of Dance, Ethan Steifel and Susan McCullough, Helen Pickett, Marius Petipa, Alexei Kremnev, and Goyo Montero. <br></br><br></br>

                After graduating from UNCSA with her Bachelor of Fine Arts, Dane moved to Atlanta where she began her career as a Freelance Artist and Dance Teacher. Here, she’s performed with Dance Canvas, AboutFace and most recently, with the Atlanta Opera in La Traviata.<br></br><br></br>

                Dane is thrilled to join the production of Frankenstein’s Funeral for her first performance with Found Stages.
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken5} alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA"/> */}
            </div>
        </div>           
        <div className="gallery-row">
            <div className="flip-gallery-item" id="joshua-headshot">
                <span className="flip-caption">
                    <h2>Joshua Whitman</h2>
                    <div className="bio-bar">    
                        <h3>Ghost of Young William</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Joshua Whitman is a fourth grade student who loves to share his passion for dance and theatre with others.<br></br><br></br>
                 He has performed in four seasons of Gwinnett Ballet Theatre’s Nutcracker and in their Alice in Wonderland show. His acting credits include Frozen Jr. at Aurora Theatre, and several productions with Deer Bear Wolf;  most notably playing Michael Darling over the course of a year with their immersive retelling of the Peter Pan story.<br></br><br></br> 
                 He loves all things spooky and scary and is thrilled to be a small part of Found Stages Frankenstein’s Funeral.
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken7} alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp"/> */}
            </div>

            <div className="flip-gallery-item" id="chris-headshot">
                <span className="flip-caption">
                    <h2>Chris Gravely</h2>
                    <div className="bio-bar">    
                        <h3>Composer, Music Director</h3>
                        <div className="bio-arrow-wrapper">
                            <h5>Read bio</h5>
                            <FaAngleDown className="bio-arrow" />
                        </div>
                    </div>
                <p className="desc">
                Chris Gravely (Composer & Music Director) is an percussionist of 18+ years. He has studied under John LaMattina, River Gueguerian, Michael Cebulski, Caleb Harron, Stuart Gerber, and Charles Settle.<br></br><br></br> 
                Chris performed two concertos with the Georgia Perimeter Wind Ensemble, and also premiered works by local Atlanta composers such as Lowell Fuchs and Michael Standard. He has played with The Dekalb Symphony Orchestra, Johns Creek Symphony Orchestra, APO, GSO, Loner, The Tin Man, Department 88, and Red Waters Quintet.<br></br><br></br>
                Chris is a composer of classical and contemporary music. He premiered his piece LIFE in 2017. Chris composed the score for Dangerous Women with The Weird Sisters Theatre project; Sanctuaries and Fortresses and Birth of Pleasure, the film adaptation of Sanctuaries and Fortresses.<br></br><br></br>
                Chris strives to show others how they too can turn a gift into a way of life through teaching percussion and drum set in Atlanta.
                </p>
                </span>
                {/* <img className="gallery-image" src={Franken8} alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp"/> */}
            </div>
            
        </div>
	</div>

    <h2 className="crew-heading">Meet the Creative Team behind Frankenstein's Funeral</h2>
    <div className="crew-gallery">
        {/* <div className="crew-card crew-1">
            <div className="crew-card-overlay">
            </div>
            <span className="crew-caption">
                <h2>Nichole Palmietto</h2>
                <h3>Producer, Director, Playwright</h3>
            </span>
        </div> */}
        <CrewCard 
            name='Nichole Palmietto'
            role="Producer, Director, Playwright"
            cardNumber='1'
            bio={<p>Nichole Palmietto (Director & Story/Concept Creator) is a co-founder of Found Stages. Nichole’s artistic work focuses on creating interactive, site-specific and immersive events that give audiences and performers a new relationship with each other, while stretching their notions of what a play “should” be. <br></br><br></br> 
            Nichole has been awarded two Alliance Theatre Reiser Atlanta Artists Lab grants (2019-20 and 2015-16, Lead Artist). She was a member of the 2016-17 Stage Directors and Choreographers Foundation Observership Class, and the resident director of Atlanta’s Working Title Playwrights Ethel Woolson Lab in 2015-16. <br></br><br></br> 
            In Atlanta, Nichole has worked with Alliance Theatre, Georgia Shakespeare, Actor’s Express Theatre, Horizon Theatre, Serenbe Playhouse, Out of Hand Theater, The Weird Sisters Theatre Project, DramaTech, Emory University, Pinch ‘n’ Ouch Theatre and Fabrefaction Theatre Conservatory. She holds degrees in theatre and public relations from The University of Texas at Austin.</p>}
            />
        <CrewCard 
            name='Neeley Gossett'
            role="Producer, Playwright"
            cardNumber='2'
            bio={<p>Neeley Gossett is a co-founder of Found Stages. Her play, Beulah Creek (nominated for The Suzi Bass Awards’ Gene-Gabriel Moore Playwriting Award) was Found Stages’ first production, held at Dunwoody Nature Center in 2014. <br></br><br></br> 
            In 2017, Alliance Theatre produced her play, Alice Between. Roman Candle Summer was a Kendeda finalist, and Decoration Day was chosen as the Alliance’s 2016 Kendeda Alumnus Reading. <br></br><br></br> 
            She is a recipient of The Reiser Atlanta Artists Lab. <br></br><br></br> 
            Her works have previously received productions and readings at The Weird Sisters Theatre Project, Theatre Emory, 7 Stages, Lark Play Development Center and many others. Several of her plays for Young Audiences are published by YouthPLAYS. Neeley holds an M.F.A. from The Playwright’s Lab at Hollins University, an M.A. in English from The University of North Carolina Wilmington and a B.A. in Theater Arts from Marymount Manhattan College. <br></br><br></br> 
            She is tenure track faculty at Georgia State University’s Perimeter College.</p>}
            />
        <CrewCard 
            name="Addae Moon"
            role="Playwright"
            cardNumber="3"
            bio={<p>Addae Moon is an Artistic Associate with Found Stages. He is an Atlanta based playwright, dramaturg, director and teaching artist<br></br><br></br> 
             Addae is a resident playwright with Maat Productions of Afrikan Centered Theatre (MPAACT) based out of Chicago, IL. He is the Director of Museum Theatre at the Atlanta History Center.<br></br><br></br> 
             As an educator, he is currently an adjunct professor in the Department of Theatre Arts at Clark Atlanta University and a playwriting instructor with Horizon Theatre Company’s Apprentice Program.<br></br><br></br> 
             Addae received his BA in Theatre Arts from Clark Atlanta University and an MFA in Playwriting from the Professional Playwright’s Program at Ohio University.</p>}
        />
        <CrewCard 
            name="Annie Harrison Elliott"
            role="Playwright"
            cardNumber="4"
            bio={<p>Annie Harrison Elliott is an associate artist with Found Stages. Found Stages produced her play Empty Rooms: A Tech Comedy, which toured to metro Atlanta tech offices. <br></br><br></br>
            Her plays have been commissioned, produced, or read by: Actor’s Express, Alliance Theatre, The Atlanta History Center, DramaTech, Working Title Playwrights, 7 Stages Curious Encounters, Weird Sisters Theatre Project, The Drama League/Wide Eyed Productions, Another Urban Riff, and others.<br></br><br></br> 
            She is currently developing a comedy TV series with Picture It Productions.<br></br><br></br>
            Annie is the co-founder of Atlanta Women in Theatre.</p>}
        />
        <CrewCard 
            name="Angela Harris"
            role="Choreographer"
            cardNumber="5"
            bio={<p>Angela Harris is the Executive Artistic Director of Dance Canvas, Inc., a career development organization for emerging professional choreographers and youth.<br></br><br></br>  
            Her choreography has been performed by The Georgia Ballet, Steps on Broadway Ensemble, Ballet Lubbock, Savannah Arts Academy, as well as other companies and schools across the country. She choreographed Bridges of Madison County (Aurora Theater), Little Shop of Horrors (Actor's Express), Willy Wonka and West Side Story (Fabrefaction Theater), The First Noel (True Colors Theatre Company) and most recently Theatrical Outfit's 110 in the Shade.<br></br><br></br>
            She received the 2011 Emerging Leader Award from Americans for the Arts and American Express.<br></br><br></br>
             Angela was one of five Inaugural National Visiting Fellows at the School of American Ballet in NYC. She received an SDCF Observership and worked with Susan Stroman on the new musical, Little Dancer, during its Development Lab for Broadway. Angela is an instructor at Dekalb School for the Arts and Academy of Ballet.</p>}
        />
        <CrewCard 
            name="Dana Woodruff"
            role="Assistant Choreographer"
            cardNumber="6"
        />
        <CrewCard 
            name="Amelia Fischer"
            role="Fight Director, Intimacy Director"
            cardNumber="7"
        />
        <CrewCard 
            name="Connor Hammond"
            role="Fight Director"
            cardNumber="8"
        />
        <CrewCard 
            name="Jennifer Schottstaedt"
            role="Costume Designer, French Consultant"
            cardNumber="9"
        />
        <CrewCard 
            name="Charles Swift"
            role="Lighting Designer"
            cardNumber="10"
            bio={<p>Charles is pleased to join this extremely talented cast and production team to present Frankenstein’s Funeral in a setting that provides an ideal backdrop to support the telling of the story in a stimulating and dynamic environment. <br></br><br></br>    
            He wishes to thank all those who support Found Stages and help make it possible for a community of artists to come together to present New Plays in unique and exciting ways.<br></br><br></br>  
            As Resident Lighting Designer at Out Front Theatre Company, he recognizes the value of the contributions that all advocates, participants and volunteers make to the arts community and the resulting benefit to the community at large.<br></br><br></br> 
            An Atlanta based Professional Theatre Consultant, Charles is a member of the ASTC (American Society of Theatre Consultants), the I.E.S. (Illuminating Engineering Society) and a member of I.A.T.S.E. He holds a B.S. in Design and Production from SUNY New Paltz (summa cum laude) and earned his M.F.A. in Scene and Lighting Design from Carnegie Mellon University.
            </p>}
        />
        <CrewCard 
            name="Chrissy Culver"
            role="Environmental Scenic Designer"
            cardNumber="11"
        />
        <CrewCard 
            name="Josh Marsh"
            role="Special Effects & Props Designer"
            cardNumber="12"
        />
        <CrewCard 
            name='Christopher Fairchild'
            role='Graphic Designer, Ephemera Designer'
            cardNumber='13'
            bio={<p>
                <a href="https://www.behance.net/gallery/26721585/other" target="_blank" rel="noopener noreferrer"><strong className="white-link">Click here to see examples of Christopher's work.</strong></a>
            </p>}
        />
          <CrewCard 
            name="Matt Baum"
            role="Magic Consultant"
            cardNumber="14"
        />
          <CrewCard 
            name="Bonnie Devon Smith"
            role="Stage Manager"
            cardNumber="15"
        />
          <CrewCard 
            name="Shelby Folks"
            role="House Manager"
            cardNumber="16"
        />
          <CrewCard 
            name="Brea Walker"
            role="Assistant Stage Manager"
            cardNumber="17"
        />
         <CrewCard 
            name="Chandler Lakin"
            role="Production Run Crew"
            cardNumber="18"
        />
         <CrewCard 
            name="Madeline McCanless"
            role="Production Run Crew"
            cardNumber="19"
        />
         <CrewCard 
            name="Lynn Livingston"
            role="Lighting Crew"
            cardNumber="20"
        />
         <CrewCard 
            name="Jessica Winer"
            role="Lighting Crew"
            cardNumber="21"
        />
    </div>
    <div className="normal-padding">
        <h2 className="glowist centered-text"><span className="light-weight">This production is made possible in part by the generous support of</span> <br></br><br></br>
        <span className="coffrins">Chris Tryba-Cofrin and David Cofrin</span></h2>
        <h3 className="centered-text border-bottom" ><strong>Thank You to Our Show Supporters</strong> <br></br><br></br>
        Stephanie Davis<br></br><br></br> 
        Clark and Lane Carlock Howard<br></br><br></br>
        and the Found Stages Board of Directors</h3>
    </div>
    <div className="no-padding">
        <h2 className="glowist centered-text">Thank You to Our Sponsors</h2>
    </div>
    <div className="crew-gallery">
        <div className="sponsor-card sponsor-1">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img src={stryant} alt=""/>
            </span>
        </div>
        <div className="sponsor-card sponsor-2">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img src={vibram} alt=""/>
            </span>
        </div>
        <div className="sponsor-card sponsor-3">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img src={avGlobal} alt="" />
            </span>
        </div>
        <div className="sponsor-card sponsor-4">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img alt="" src={gaslight} />
            </span>
        </div>
        <div className="sponsor-card sponsor-5">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img alt="" src={jordan} />
            </span>
        </div>
        <div className="sponsor-card sponsor-6">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img alt="" src={odditees} />
            </span>
        </div>
        <div className="sponsor-card sponsor-7">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img alt="" src={casey} />
            </span>
        </div>
        <div className="sponsor-card sponsor-8">
        <div className="sponsor-card-overlay">
            </div>
            <span className="sponsor-caption">
                <img alt="" src={dap} />
            </span>
        </div>
    </div>
</div>
  );
}